import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A healthy family is a happy family. The Family Medicine and Wellness department at Restore Health aims to make your health our priority. Our goal is to redefine primary health care, to provide guidance on positive lifestyle changes and empower you with the knowledge to take informed decisions. Above all, we emphasize on patient-physician relationship, with the patient viewed in the context of the family and subsequently, the society as a whole. This unique specialty encompasses all ages, sexes, every  organ system and every disease entity. Family physicians have an unparalleled opportunity to impact health and wellness throughout an individual's lifetime.`}</p>
    <p>{`At RH, in addition to providing comprehensive care, we provide personalized treatment plans, individual care and support. "Treatment without prevention is unsustainable." In keeping with this, we delve extensively into wellness programs, health education sessions(individual/group), adult immunization programs & timely health check ups. In coordination with the physicians of infectious disease care and psychology, we provide an  all rounded care to ensure a happy, healthy life.`}</p>
    <p>{`We manage acute illnesses, chronic diseases like diabetes, hypertension, obesity, asthma, COPD, liver diseases, migraine, stroke and more. Our team also has expertise in the management of infectious diseases  like flu, hepatitis, STIs, HIV etc.`}</p>
    <p>{`Additional Services provided:`}</p>
    <ol>
      <li parentName="ol">{`General health check up`}</li>
      <li parentName="ol">{`Master health check up`}</li>
      <li parentName="ol">{`Diabetes and hypertensive care`}</li>
      <li parentName="ol">{`Women's general health`}</li>
      <li parentName="ol">{`Adolescent health`}</li>
      <li parentName="ol">{`Elderly health care`}</li>
      <li parentName="ol">{`Sexual health`}</li>
    </ol>
    <p>{`All your health care needs met at one convenient location.`}</p>
    <h2>{`Diabetes and Wellness`}</h2>
    <p>{`Diabetes is a life-long condition and people have times when they are struggling with self-management.  At Restore Health, we use several well-validated, reliable, and easy-to-use questionnaires to assess the emotional needs and health of people living with diabetes.`}</p>
    <p>{`The Diabetes & Wellness program is designed to deliver expertise of the highest level. We manage insulin dependent, non insulin dependent diabetes as well as diabetes in pregnancy. With health education sessions, customized diet plans, self management tips and strategies along with appropriate medications, health goals and regular check ups, this program provides a reliable and tested approach to managing this long term condition. It puts the patient at the center of the medical decision and helps to develop a long term, trustworthy relation between the patient and the doctor.  We believe that this is the model that patients across the world need, but do not have access to. This program ensures better health outcomes and paves the path for sustainable medicine.`}</p>
    <h3>{`Individualized treatment plans`}</h3>
    <p>{`Including diet counselling, setting of goals and guidance on lifestyle`}</p>
    <h3>{`Expert physician care`}</h3>
    <p>{`Health check ups, interpretation and evaluation of reports by our experienced physicians`}</p>
    <h3>{`Progress monitoring`}</h3>
    <p>{`Close assessment of progress, review of medicine and diet and lifestyle`}</p>
    <h3>{`Teleconsultation services`}</h3>
    <p>{`Our physicians consult over the phone or video call`}</p>
    <h3>{`Health education`}</h3>
    <p>{`For patient, patient family and peer support sessions held every month`}</p>
    <h3>{`Goal oriented`}</h3>
    <p>{`Achievable goals in lifestyle, diet and blood sugar maintenance`}</p>
    <h2>{`Weight and Wellness`}</h2>
    <h4>{`What is our Weight & Wellness program?`}</h4>
    <p>{`At Restore Health, we help you improve your quality of life by inculcating healthy eating habits, exercise regimens and weight management.`}</p>
    <p>{`Here, we firmly believe that “weight is all about health and not just appearance” and we cultivate the same belief in our patients.  Contrary to the popular belief that weight represents health, you will see us celebrating a lot of non-scalable victories like increased physical energy (so that you can take stairs or play with your children and grandchildren!) and increased self-esteem and of course, fitting into your favorite dress!`}</p>
    <p>{`Remember, being overweight and the health issues associated with it are preventable and reversible, if the right approach is taken.`}</p>
    <h4>{`How is being a part of this program different from regular consultations?`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Do you know what a marathon is?
Maintaining weight is like a marathon. A marathon involves a group of people running to reach the same goal. Few will reach their destination early, a few will reach late; some individuals have to put in  more effort, while some less. Co-participants will encourage you and will help you stay motivated to reach your goal. Similarly, being a part of this program helps you get peer support and to be goal oriented.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`While many of us diet or exercise to lose weight, keeping that weight off is where the struggle lies. Through our program you will learn how to maintain your weight with our continuous support.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Losing weight is as important as ensuring a healthy weight loss. Timely health checks, careful supervision by our physicians help to prevent any adverse effects to your health throughout the program.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Having a goal weight can keep you motivated but true success comes from daily, sustainable and enjoyable eating and physical activity.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`We know that being overweight is a personal problem and we will personalize the solution for you, taking into account various personal parameters.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Remember- Don’t start a diet that has an expiry date, create a life style that lasts forever`}</p>
      </li>
    </ol>
    <h2>{`Corporate Wellness`}</h2>
    <p>{`Through our corporate wellness program we provide customized plans depending on interests and needs of your company. This enables employers to reduce absenteeism, improve health and well-being of their employees while enhancing their productivity and lowering health care claims.`}</p>
    <p>{`Services we provide through our corporate wellness program are annual health checks, pre-employment health checks, vaccinations, psychology services, health education sessions.`}</p>
    <p>{`Our medical experts organize interactive talks and seminars on a wide range of work-site related health and wellness topics. Over 100 unique topics to choose from such as diabetes prevention, cardiovascular health, women’s health, nutrition, ergonomics, health and safety and more. Contact us to know more!`}</p>
    <h2>{`Adult Immunization`}</h2>
    <p>{`Vaccines are NOT just for children. Adults need them too.`}</p>
    <p>{`Every year hundreds of individuals fall sick due to illnesses that can be prevented.  These diseases are the leading causes for death, admission to hospitals, lengthy bills and absenteeism from work. Regardless of age, adults need vaccines to protect themselves and their loved ones from serious and at times, deadly diseases.`}</p>
    <p>{`We at PCMH Restore Health Center aim to increase the levels of understanding, awareness and access to adult vaccinations. Vaccines are provided as per the Center for Disease Control (CDC) guidelines. While some vaccines are recommended for all individuals, other vaccines are given based on a person’s job, lifestyle, age and coexisting health issues. In keeping with our motto ‘wellness, our passion’, we at RH strive to prevent disease, improve quality of life in terms of well being and health.`}</p>
    <p>{`Vaccines not only help to reduce your chances of acquiring the disease but also help to protect those around you as well. Many infants, elderly and individuals who are sick and cannot take the vaccines depend on you to prevent the spread of these diseases. For example, a preventable disease like whooping cough can lead to fatal consequences in the elderly or pregnant women`}</p>
    <p>{`Vaccine Preventable Diseases`}</p>
    <p>{`Prevent and Protect`}</p>
    <ul>
      <li parentName="ul">{`Ask your health care provider all the details about adult vaccinations`}</li>
      <li parentName="ul">{`If you have a health care record and/or vaccination record bring it with you to your physician`}</li>
      <li parentName="ul">{`Report any reaction to a vaccine`}</li>
      <li parentName="ul">{`Always complete the number of doses for a vaccine. Remember skipping vaccine doses does not provide immunity`}</li>
      <li parentName="ul">{`Tell your provider if you are or trying to get pregnant
• Discuss other health issues with your provider`}</li>
    </ul>
    <h4>{`Know Your Schedule`}</h4>
    <p><a parentName="p" {...{
        "href": "https://www.cdc.gov/vaccines/schedules/hcp/imz/adult-compliant.html"
      }}>{`CDC adult vaccine schedule`}</a></p>
    <p>{`Here are a list of frequently asked questions:`}</p>
    <h4>{`1. Are vaccines safe?`}</h4>
    <p>{`The vaccines we provide are FDA approved and the guidelines are prescribed by the Center for Disease Control (CDC). The vaccines are continually tested, tracked and monitored for safety.`}</p>
    <h4>{`2. Will I experience side effects with any vaccines?`}</h4>
    <p>{`Vaccine side effects, if any, are mild and temporary. Severe side effects are rare.`}</p>
    <h4>{`3. How do I remember when to take my next vaccine dose?`}</h4>
    <p>{`We provide vaccination card which is detailed with the names of the vaccines and your next scheduled date. It is important to keep this card with you at every visit to the clinic.
We also have an electronic medical record system which keeps a track of your vaccination schedule.`}</p>
    <h4>{`4. I have recently been diagnosed with diabetes, is it okay to take vaccines?`}</h4>
    <p>{`While most vaccines help boost the immunity, it is important to be thoroughly examined first. Individuals with or without health issues will always undergo a physical checkup before being prescribed vaccinations.`}</p>
    <h4>{`5. I have taken vaccination as a child. Why do I need to take it again?`}</h4>
    <p>{`Some vaccines taken as a child have limited period of efficacy and it is important to take them again. There are still other vaccines which are specifically for diseases in adults.`}</p>
    <h4>{`6. I am young and maintain my health. Do I still need vaccination?`}</h4>
    <p>{`While infants and the elderly are at a greater risk for serious infections and complications in many cases, vaccine-preventable diseases can strike anyone. If you’re young and healthy, getting vaccinated can help you stay that way.`}</p>
    <h4>{`7. I am travelling abroad. What vaccines do I need to take?`}</h4>
    <p>{`Depending on the country you will be travelling to; the type of vaccine differs. Consult our doctors to know which vaccines are right for you.`}</p>
    <h3>{`Adult Immunization Program`}</h3>
    <p>{`This is a unique initiative by PCMH Restore Health Center. With increasing responsibilities at work, hectic schedules and busy life, more and more individuals are compromising on health leading to an ever-rising loss of work days owing to sickness and disease.`}</p>
    <p>{`Keeping this in mind, this program has been designed to reach out to companies, conduct talks on the benefits of adult immunization and health care, set up on site vaccination camps and provide exclusive vaccination packages for the benefit of the employees.`}</p>
    <p>{`For more information or queries, please leave your questions and contact below.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      